"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PATCH = exports.TRACE = exports.OPTIONS = exports.CONNECT = exports.DELETE = exports.PUT = exports.POST = exports.HEAD = exports.GET = void 0;
exports.GET = 'GET';
exports.HEAD = 'HEAD';
exports.POST = 'POST';
exports.PUT = 'PUT';
exports.DELETE = 'DELETE';
exports.CONNECT = 'CONNECT';
exports.OPTIONS = 'OPTIONS';
exports.TRACE = 'TRACE';
exports.PATCH = 'PATCH';