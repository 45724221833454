"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useElementId = void 0;
const react_1 = require("react");
const useUuid_1 = require("./useUuid");
const useElementId = () => {
  const uuid = (0, useUuid_1.useUuid)();
  return (0, react_1.useMemo)(() => `id_${uuid}`, [uuid]);
};
exports.useElementId = useElementId;