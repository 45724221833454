"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SCROLL = exports.LOAD = exports.DROP = exports.DRAG_LEAVE = exports.DRAG_ENTER = exports.DRAG_OVER = exports.DRAG_END = exports.DRAG_START = exports.DRAG = exports.SUBMIT = exports.TOUCH_END = exports.TOUCH_MOVE = exports.TOUCH_START = exports.BEFORE_UNLOAD = exports.BLUR = exports.FOCUS = exports.CHANGE = exports.RESIZE = exports.KEY_UP = exports.KEY_DOWN = exports.KEY_PRESS = exports.MOUSE_MOVE = exports.MOUSE_OUT = exports.MOUSE_OVER = exports.MOUSE_WHEEL = exports.MOUSE_UP = exports.MOUSE_LEAVE = exports.MOUSE_ENTER = exports.MOUSE_DOWN = exports.CLICK = void 0;
exports.CLICK = 'click';
exports.MOUSE_DOWN = 'mousedown';
exports.MOUSE_ENTER = 'mouseenter';
exports.MOUSE_LEAVE = 'mouseleave';
exports.MOUSE_UP = 'mouseup';
exports.MOUSE_WHEEL = 'wheel';
exports.MOUSE_OVER = 'mouseover';
exports.MOUSE_OUT = 'mouseout';
exports.MOUSE_MOVE = 'mousemove';
exports.KEY_PRESS = 'keypress';
exports.KEY_DOWN = 'keydown';
exports.KEY_UP = 'keyup';
exports.RESIZE = 'resize';
exports.CHANGE = 'change';
exports.FOCUS = 'focus';
exports.BLUR = 'blur';
exports.BEFORE_UNLOAD = 'beforeunload';
exports.TOUCH_START = 'touchstart';
exports.TOUCH_MOVE = 'touchmove';
exports.TOUCH_END = 'touchend';
exports.SUBMIT = 'submit';
exports.DRAG = 'drag';
exports.DRAG_START = 'dragstart';
exports.DRAG_END = 'dragend';
exports.DRAG_OVER = 'dragover';
exports.DRAG_ENTER = 'dragenter';
exports.DRAG_LEAVE = 'dragleave';
exports.DROP = 'drop';
exports.LOAD = 'load';
exports.SCROLL = 'scroll';