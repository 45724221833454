import { appConfig } from '@sqvr/utils';

async function initializeApp() {
  // Подгрузим текста локализации
  const { prepareLocale } = await import(/* webpackChunkName: "i18n" */ '@sqvr/i18n');
  await prepareLocale(import(/* webpackChunkName: "i18n-bundle" */ '@sqvr/i18n/build/ru'), 'ru');

  // Загрузим файл конфига с основными настройками приложения
  await appConfig.prepare();

  // Загрущим модуль core с основной логикой приложения
  const { getCerebralApp } = await import(/* webpackChunkName: "core" */ '@sqvr/core');

  const { startAppAppForBackbone } = await import(
    /* webpackChunkName: "app-startup-pipeline" */ './appStartupPipeline'
  );

  // Подготовим всю экосистему для реакта со сторами
  await startAppAppForBackbone();

  // Загрузим необходимые компоненты для бекбона
  const { componentsForBackbone } = await import(/* webpackChunkName: "react-components" */ './componentForBackbone');

  window.reactAppAPI.setComponents(componentsForBackbone);

  const cerebralApp = getCerebralApp();

  // Запустим главную секвенуцию инициализации
  await cerebralApp.getSequence('initialize')();

  window.reactAppAPI.initializeComponentContainer();
  window.reactAppAPI.renderPortals();
}

// eslint-disable-next-line no-console
initializeApp().catch(err => console.error('ERR', err));
