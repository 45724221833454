"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actSolver = void 0;
const actSolver = () => {
  const mockConsoleMethod = realConsoleMethod => {
    const ignoredMessages = ['test was not wrapped in act(...)'];
    return function (message) {
      const containsIgnoredMessage = ignoredMessages.some(ignoredMessage => message.includes(ignoredMessage));
      if (!containsIgnoredMessage) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        realConsoleMethod(message, ...args);
      }
    };
  };
  console.warn = jest.fn(mockConsoleMethod(console.warn));
  console.error = jest.fn(mockConsoleMethod(console.error));
};
exports.actSolver = actSolver;