"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.YUP_EMAIL_REGEXP = exports.PHONE_MASK_REGEX = exports.USER_NAME_REGEXP = exports.yupTestCreditCardHolder = exports.yupTestCardCVV = exports.yupTestCardYear = exports.yupTestCardMonth = exports.checkCardDateExpiring = exports.validateCardNumber = void 0;
const validateCardNumber = str => {
  const cardNumber = (typeof str === 'string' && str || '').replace(/[^\d+]/gm, '');
  if (cardNumber.length === 0) return false;
  const amexCard = /^3[47][0-9]{13}$/;
  const bcGlobal = /^(6541|6556)[0-9]{12}$/;
  const carteBlancheCard = /^389[0-9]{11}$/;
  const dinersClubCard = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
  const discoverCard = /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;
  const instaPaymentCard = /^63[7-9][0-9]{13}$/;
  const jcbCard = /^(?:2131|1800|35\d{3})\d{11}$/;
  const koreanLocalCard = /^9[0-9]{15}$/;
  const laserCard = /^(6304|6706|6709|6771)[0-9]{12,15}$/;
  const maestroCard = /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/;
  const mastercard = /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
  const soloCard = /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/;
  const switchCard = /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/;
  const unionPayCard = /^(62[0-9]{14,17})$/;
  const visaCard = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const visaMasterCard = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/;
  return amexCard.test(cardNumber) || bcGlobal.test(cardNumber) || carteBlancheCard.test(cardNumber) || dinersClubCard.test(cardNumber) || discoverCard.test(cardNumber) || instaPaymentCard.test(cardNumber) || jcbCard.test(cardNumber) || koreanLocalCard.test(cardNumber) || laserCard.test(cardNumber) || maestroCard.test(cardNumber) || mastercard.test(cardNumber) || soloCard.test(cardNumber) || switchCard.test(cardNumber) || unionPayCard.test(cardNumber) || visaCard.test(cardNumber) || visaMasterCard.test(cardNumber);
};
exports.validateCardNumber = validateCardNumber;
function checkCardDateExpiring(month, year) {
  if (!month || !year) {
    return true;
  }
  return new Date() < new Date(`20${year}-${month}-01`);
}
exports.checkCardDateExpiring = checkCardDateExpiring;
const yupTestCardMonth = function (value) {
  return value && /^((0[1-9])|(1[0-2]))$/.test(value) && checkCardDateExpiring(value, this.parent.year);
};
exports.yupTestCardMonth = yupTestCardMonth;
const yupTestCardYear = function (value) {
  return value && /^\d{2}$/.test(value) && checkCardDateExpiring(this.parent.month, value);
};
exports.yupTestCardYear = yupTestCardYear;
const yupTestCardCVV = value => value && /^\d{3,4}$/.test(value);
exports.yupTestCardCVV = yupTestCardCVV;
function yupTestCreditCardHolder(value) {
  return value && /^[a-zA-Z\s'`~\-.]{3,}\s[a-zA-Z\s'`~\-.]{3,}$/.test(value);
}
exports.yupTestCreditCardHolder = yupTestCreditCardHolder;
exports.USER_NAME_REGEXP = /^[А-Яа-яЁё0-9\s-.,;-_']+$/;
exports.PHONE_MASK_REGEX = /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/;
exports.YUP_EMAIL_REGEXP = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;