"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileTypeByName = exports.FILE_EXTENSIONS_BY_TYPE = void 0;
const AllowedExtensions_1 = require("./AllowedExtensions");
exports.FILE_EXTENSIONS_BY_TYPE = {
  generalImages: ['png', 'jpg', 'jpeg'],
  image: AllowedExtensions_1.ALLOWED_PICTURE_EXTENSIONS,
  excel: ['xlsx', 'xls'],
  pdf: ['pdf'],
  word: ['doc', 'docx'],
  audio: AllowedExtensions_1.ALLOWED_AUDIO_EXTENSIONS,
  archive: AllowedExtensions_1.ALLOWED_ARCHIVE_EXTENSIONS,
  video: AllowedExtensions_1.ALLOWED_VIDEO_EXTENSIONS
};
function getFileTypeByName(fileName) {
  function fileTypeToRegExp(fileType) {
    return new RegExp(`.(${exports.FILE_EXTENSIONS_BY_TYPE[fileType].map(v => `.${v}`).join('|')})$`);
  }
  const fileNameToLowerCase = fileName.toLowerCase();
  const excelPattern = fileTypeToRegExp('excel');
  const imagePattern = fileTypeToRegExp('image');
  const generalImagesPattern = fileTypeToRegExp('generalImages');
  const wordPattern = fileTypeToRegExp('word');
  const pdfPattern = fileTypeToRegExp('pdf');
  const audioPattern = fileTypeToRegExp('audio');
  const archivePattern = fileTypeToRegExp('archive');
  const videoPattern = fileTypeToRegExp('video');
  if (excelPattern.test(fileNameToLowerCase)) {
    return 'excel';
  } else if (imagePattern.test(fileNameToLowerCase)) {
    return 'image';
  } else if (generalImagesPattern.test(fileNameToLowerCase)) {
    return 'image';
  } else if (wordPattern.test(fileNameToLowerCase)) {
    return 'word';
  } else if (pdfPattern.test(fileNameToLowerCase)) {
    return 'pdf';
  } else if (audioPattern.test(fileNameToLowerCase)) {
    return 'audio';
  } else if (archivePattern.test(fileNameToLowerCase)) {
    return 'archive';
  } else if (videoPattern.test(fileNameToLowerCase)) {
    return 'video';
  } else return 'unknown';
}
exports.getFileTypeByName = getFileTypeByName;