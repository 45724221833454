"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prepareUnsafeHtml = void 0;
const unsafeMap = {
  '&amp;': '&',
  '&nbsp;': ' ',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#39;': "'",
  '&#x2F;': '/',
  '&#x60;': '`',
  '&#x3D;': '=',
  '&#171;': '"',
  '&#187;': '"'
};
function prepareUnsafeHtml(value) {
  return String(value).replace(/&(.*?);/g, function (s) {
    return unsafeMap[s] || s;
  });
}
exports.prepareUnsafeHtml = prepareUnsafeHtml;
function htmlToText(html) {
  if (!html) return '';
  html = html.replace(/<(.|\n)*?>/g, '');
  html = prepareUnsafeHtml(html);
  return html;
}
exports.default = htmlToText;