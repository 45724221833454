"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function isJSONObject(value) {
  if (!value) return false;
  if (typeof value === 'string') {
    try {
      return Object.keys(JSON.parse(value)).length > 0;
    } catch (e) {}
  }
  return false;
}
exports.default = isJSONObject;