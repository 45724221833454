"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const max_1 = require("libphonenumber-js/max");
const i18n_1 = require("../i18n");
function isPhone(value, mustBeMobile) {
  let allowAllCountries = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!value) return false;
  if (value.startsWith('+') && value.replaceAll(/[^0-9]+/g, '').length > 11) {
    return false;
  }
  try {
    const parsedPhone = (0, max_1.parsePhoneNumber)(value, i18n_1.DEFAULT_COUNTRY_CODE);
    return parsedPhone.isValid() && (!mustBeMobile || parsedPhone.getType() === 'MOBILE') && (allowAllCountries || parsedPhone.country === i18n_1.DEFAULT_COUNTRY_CODE);
  } catch (e) {
    return false;
  }
}
exports.default = isPhone;