"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPointInsideContainer = exports.isElementInsideContainer = void 0;
function isElementInsideContainer(element, container) {
  if (!element || !container) return false;
  let parent = element.parentElement;
  while (parent) {
    if (parent === container) return true;
    parent = parent.parentElement;
  }
  return false;
}
exports.isElementInsideContainer = isElementInsideContainer;
function isPointInsideContainer(point, container) {
  if (!point || !container) return;
  const bounds = container.getBoundingClientRect();
  return bounds.left < point.x && bounds.right > point.x && bounds.top < point.y && bounds.bottom > point.y;
}
exports.isPointInsideContainer = isPointInsideContainer;