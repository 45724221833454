"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function joinNotEmpty(words) {
  let separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ' ';
  let trim = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  let result = '';
  words.forEach(word => {
    const trimmed = (word || '').trim();
    if (word && (!trim || trimmed)) {
      if (result.length > 0) result += separator;
      result += trim ? word : trimmed;
    }
  });
  return result;
}
exports.default = joinNotEmpty;