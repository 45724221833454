"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function mockQuillDocumentAPI() {
  if (process.env.NODE_ENV !== 'test') {
    throw new Error('The mockQuillDocumentAPI utility can be used only in test environment');
  }
  document.getSelection = () => {
    return {
      getRangeAt: () => {},
      removeAllRanges: () => null,
      addRange: () => null
    };
  };
  document.createRange = () => ({
    setStart: () => null,
    setEnd: () => null
  });
}
exports.default = mockQuillDocumentAPI;