"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toPx = exports.toRem = exports.toEm = void 0;
const BASE_PIXEL_SIZE = 16;
const toNumber = value => typeof value === 'number' ? value : parseFloat(value);
const isUnitType = (value, screenUnit) => {
  const regExp = new RegExp(`${screenUnit}$`);
  if (typeof value === 'number' && screenUnit === 'px') return true;
  return regExp.test(value);
};
const toEm = function (value) {
  let base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BASE_PIXEL_SIZE;
  const numberValue = toNumber(value);
  if (isUnitType(value, 'px')) return `${(numberValue / base).toFixed(2)}em`;
  if (isUnitType(value, 'em') || isUnitType(value, 'rem')) return `${numberValue.toFixed(2)}em`;
};
exports.toEm = toEm;
const toRem = function (value) {
  let base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BASE_PIXEL_SIZE;
  const numberValue = toNumber(value);
  if (isUnitType(value, 'px')) return `${(numberValue / base).toFixed(2)}rem`;
  if (isUnitType(value, 'em') || isUnitType(value, 'rem')) return `${numberValue.toFixed(2)}rem`;
};
exports.toRem = toRem;
const toPx = function (value) {
  let base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : BASE_PIXEL_SIZE;
  const numberValue = toNumber(value);
  if (isUnitType(value, 'px')) return `${numberValue.toFixed(2)}px`;
  if (isUnitType(value, 'em') || isUnitType(value, 'rem')) return `${(numberValue * base).toFixed(2)}px`;
};
exports.toPx = toPx;