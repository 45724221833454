"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function removeExtraFractionZeros(value) {
  if (!value) return '';
  const integerPart = value.split(',')[0];
  let fractionPart = value.split(',')[1];
  if (!fractionPart) return value;
  while (fractionPart.endsWith('0')) {
    fractionPart = fractionPart.substring(0, fractionPart.length - 1);
  }
  if (!fractionPart) return integerPart;
  return `${integerPart},${fractionPart}`;
}
exports.default = removeExtraFractionZeros;