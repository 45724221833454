"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asyncQuerySelector = void 0;
function asyncQuerySelector(selector, waitLimit) {
  return new Promise((resolve, reject) => {
    if (waitLimit) {
      setTimeout(clearInterval, waitLimit, setInterval(() => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
        }
      }, 0));
      if (!document.querySelector(selector)) {
        reject(new Error(`Element by selector "${selector}" not found`));
      }
    } else {
      const interval = setInterval(() => {
        const element = document.querySelector(selector);
        if (element) {
          clearInterval(interval);
          resolve(element);
        }
      }, 0);
    }
  });
}
exports.asyncQuerySelector = asyncQuerySelector;