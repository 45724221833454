"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatSecondsForCountdown = exports.formatDateToServerWithoutTime = exports.formatDateToServer = exports.formatServerDate = exports.formatServerDateWithTime = exports.formatDateWithTime = exports.parseServerDate = exports.formatDate = exports.date = exports.haveIntersectionWithDateRanges = exports.DATE_FORMATS = exports.PM = exports.AM = void 0;
const date_fns_1 = require("date-fns");
const locale_1 = require("date-fns/locale");
const dateFnsLocales = {
  ru: locale_1.ru
};
exports.AM = 'AM';
exports.PM = 'PM';
exports.DATE_FORMATS = {
  time: 'HH:mm',
  year: 'dd.MM.yyyy',
  monthDay: 'd MMM'
};
function haveIntersectionWithDateRanges(date, range) {
  if (!date || !range || range.length === 0) return false;
  const clearedDate = (0, date_fns_1.startOfDay)(date);
  for (const value of range) {
    if (value instanceof Date) {
      if ((0, date_fns_1.isEqual)(clearedDate, (0, date_fns_1.startOfDay)(value))) {
        return true;
      }
    } else if (value.start && !value.end && (0, date_fns_1.startOfDay)(value.start) <= clearedDate) {
      return true;
    } else if (!value.start && value.end && (0, date_fns_1.startOfDay)(value.end) >= clearedDate) {
      return true;
    } else if (value.start && value.end && (0, date_fns_1.startOfDay)(value.start) <= clearedDate && (0, date_fns_1.startOfDay)(value.end) >= clearedDate) {
      return true;
    }
  }
  return false;
}
exports.haveIntersectionWithDateRanges = haveIntersectionWithDateRanges;
function date(source) {
  if (!source) return null;
  if (!source.match(/.+\d\d:\d\d/)) {
    return (0, date_fns_1.parse)(source, 'dd.MM.yyyy', new Date());
  } else {
    return (0, date_fns_1.parse)(source, 'dd.MM.yyyy HH:mm', new Date());
  }
}
exports.date = date;
const formatDate = function (date) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!(date instanceof Date)) {
    throw new Error('date must be an instance of Date');
  }
  const {
    includeTime = false,
    customFormat,
    locale = 'ru'
  } = options;
  const selectedLocale = dateFnsLocales[locale] || locale_1.ru;
  let formattedDate;
  if (customFormat) {
    formattedDate = (0, date_fns_1.format)(date, customFormat, {
      locale: selectedLocale
    });
  } else {
    if ((0, date_fns_1.isToday)(date)) {
      formattedDate = (0, date_fns_1.format)(date, exports.DATE_FORMATS.time, {
        locale: selectedLocale
      });
    } else if (!(0, date_fns_1.isThisYear)(date)) {
      formattedDate = (0, date_fns_1.format)(date, exports.DATE_FORMATS.year, {
        locale: selectedLocale
      });
    } else {
      formattedDate = (0, date_fns_1.format)(date, exports.DATE_FORMATS.monthDay, {
        locale: selectedLocale
      }).replace('.', '');
      const parts = formattedDate.split(' ');
      const day = parts[0];
      let month = parts[1];
      if (month.length > 3) {
        month = month.slice(0, 3);
      }
      formattedDate = `${day} ${month}`;
    }
  }
  const shouldDisplayTime = includeTime && (!(0, date_fns_1.isToday)(date) || customFormat);
  if (shouldDisplayTime) {
    formattedDate += ` в ${(0, date_fns_1.format)(date, exports.DATE_FORMATS.time, {
      locale: selectedLocale
    })}`;
  }
  return formattedDate;
};
exports.formatDate = formatDate;
function parseServerDate(date) {
  if (!date) return null;
  return (0, date_fns_1.parseISO)(date);
}
exports.parseServerDate = parseServerDate;
function formatDateWithTime(date) {
  let formatString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'd MMMM yyyy, HH:mm';
  let locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'ru';
  if (!date) return '';
  return (0, date_fns_1.format)(date, formatString, {
    locale: dateFnsLocales[locale]
  });
}
exports.formatDateWithTime = formatDateWithTime;
function formatServerDateWithTime(date) {
  if (!date) return '';
  return formatDateWithTime(parseServerDate(date));
}
exports.formatServerDateWithTime = formatServerDateWithTime;
function formatServerDate(date) {
  if (!date) return '';
  return (0, exports.formatDate)(parseServerDate(date));
}
exports.formatServerDate = formatServerDate;
function formatDateToServer(date) {
  if ((0, date_fns_1.isValid)(date)) {
    return (0, date_fns_1.format)(date, "yyyy-MM-dd'T'HH:mm");
  }
  return null;
}
exports.formatDateToServer = formatDateToServer;
function formatDateToServerWithoutTime(date) {
  if ((0, date_fns_1.isValid)(date)) {
    return (0, date_fns_1.format)(date, 'yyyy-MM-dd');
  }
  return null;
}
exports.formatDateToServerWithoutTime = formatDateToServerWithoutTime;
function formatSecondsForCountdown(totalSeconds) {
  if (totalSeconds < 0) {
    totalSeconds = 0;
  }
  let [hours, minutes, seconds] = ['', '', ''];
  if (totalSeconds > 3600) {
    hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
    totalSeconds = totalSeconds % 3600;
  }
  minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
  seconds = (totalSeconds % 60).toString().padStart(2, '0');
  return `${hours ? hours + ':' : ''}${minutes}:${seconds}`;
}
exports.formatSecondsForCountdown = formatSecondsForCountdown;