"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function stripTextFromHTML(html) {
  if (!html) return '';
  const document = new DOMParser().parseFromString(html, 'text/html');
  Array.from(document.getElementsByTagName('BR')).forEach(br => {
    br.replaceWith(document.createElement('BR'), '\n');
  });
  let result = (document.body.textContent || '').replace(/\uFEFF/g, '');
  if (result.length && (result.match(/\n/g) || []).length === result.length) {
    result = result.slice(1);
  }
  return result;
}
exports.default = stripTextFromHTML;