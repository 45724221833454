"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ALLOWED_EXTENSIONS_WITHOUT_PICTURES = exports.ALLOWED_EXTENSIONS = exports.ALLOWED_ARCHIVE_EXTENSIONS = exports.ALLOWED_DOCUMENT_EXTENSIONS = exports.ALLOWED_VIDEO_EXTENSIONS = exports.ALLOWED_AUDIO_EXTENSIONS = exports.ALLOWED_PICTURE_EXTENSIONS = void 0;
exports.ALLOWED_PICTURE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'bmp', 'tif', 'tiff', 'tga', 'gif', 'heic', 'heif', 'webp'];
exports.ALLOWED_AUDIO_EXTENSIONS = ['mp3', 'wma', 'amr', 'ogg', 'aac', 'wav', 'flac'];
exports.ALLOWED_VIDEO_EXTENSIONS = ['avi', 'mp4', '3gp', 'mpeg', 'mov', 'flv', 'wmv', 'mkv', 'ts', 'vob', 'm4a', 'mpg'];
exports.ALLOWED_DOCUMENT_EXTENSIONS = ['txt', 'pdf', 'doc', 'docx', 'rtf', 'xlsx', 'xls'];
exports.ALLOWED_ARCHIVE_EXTENSIONS = ['zip', 'rar'];
exports.ALLOWED_EXTENSIONS = [...exports.ALLOWED_AUDIO_EXTENSIONS, ...exports.ALLOWED_VIDEO_EXTENSIONS, ...exports.ALLOWED_PICTURE_EXTENSIONS, ...exports.ALLOWED_DOCUMENT_EXTENSIONS, ...exports.ALLOWED_ARCHIVE_EXTENSIONS];
exports.ALLOWED_EXTENSIONS_WITHOUT_PICTURES = [...exports.ALLOWED_AUDIO_EXTENSIONS, ...exports.ALLOWED_VIDEO_EXTENSIONS, ...exports.ALLOWED_DOCUMENT_EXTENSIONS, ...exports.ALLOWED_ARCHIVE_EXTENSIONS];