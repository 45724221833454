"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function getHeadNodes() {
  return Array.from(document.head.childNodes);
}
function isStyledNode(node) {
  return node.localName === 'style' && node.hasAttribute('data-styled');
}
function isMuiNode(node) {
  return node.localName === 'style' && node.hasAttribute('data-jss') && node.hasAttribute('data-meta');
}
function getFirstStyledNodeIndex() {
  const headNodes = Array.from(document.head.childNodes);
  return headNodes.findIndex(node => isStyledNode(node));
}
function getAddedCorrectMuiStyles() {
  const firstStyledNodeIndex = getFirstStyledNodeIndex();
  const styles = new Map();
  const headNodes = Array.from(document.head.childNodes);
  for (const node of headNodes) {
    if (firstStyledNodeIndex > -1 && isStyledNode(node)) {
      break;
    }
    if (isMuiNode(node)) {
      const styleName = node.getAttribute('data-meta');
      if (!styles.has(styleName)) {
        styles.set(styleName, []);
      }
      styles.get(styleName).push({
        style: styleName,
        node
      });
    }
  }
  return styles;
}
const onNodeAdd = mutationsList => {
  for (let mutation of mutationsList) {
    const addedNodes = Array.from(mutation.addedNodes);
    if (addedNodes.length > 0 && addedNodes.some(n => n.localName === 'style')) {
      addedNodes.forEach(node => {
        if (isMuiNode(node)) {
          const firstStyledNodeIndex = getFirstStyledNodeIndex();
          if (firstStyledNodeIndex === -1) return;
          const headNodes = getHeadNodes();
          const styleName = node.getAttribute('data-meta');
          const nodeIndex = headNodes.indexOf(node.previousSibling) + 1;
          const correctNodesByStyle = getAddedCorrectMuiStyles();
          const existNodes = correctNodesByStyle.get(styleName);
          if (existNodes && existNodes.length > 0 && nodeIndex > firstStyledNodeIndex) {
            console.warn(`Remove the style node ${node.getAttribute('data-meta')} added after styled nodes`);
            node.remove();
          }
        }
      });
    }
  }
};
const observer = new MutationObserver(onNodeAdd);
function correctHeadStyles() {
  observer.observe(document.head, {
    childList: true
  });
}
exports.default = correctHeadStyles;