"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const react_1 = require("react");
class ErrorBoundary extends react_1.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}
exports.default = ErrorBoundary;