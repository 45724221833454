"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppConfig = exports.APP_CONFIG_FILE_NAME = void 0;
exports.APP_CONFIG_FILE_NAME = 'react-app-runtime-config.json';
class AppConfig {
  constructor() {
    this._config = {};
    this.computedAppHost = '';
    this.calculateAppHostFromUrl = () => {
      const host = window.location.host;
      let parts = host.split('.');
      let hostPartsNumWithoutCode = 2;
      if (host.endsWith('kube-main.sqvr.net')) {
        hostPartsNumWithoutCode = 4;
      }
      if (host.endsWith('local.sqvr.net')) {
        hostPartsNumWithoutCode = 3;
      }
      if (parts.length > hostPartsNumWithoutCode) {
        parts.splice(0, parts.length - hostPartsNumWithoutCode);
      }
      return parts.join('.');
    };
  }
  prepare() {
    return __awaiter(this, void 0, void 0, function* () {
      function handleError() {
        document.getElementById('root').innerHTML = `No "${exports.APP_CONFIG_FILE_NAME}" file found`;
        throw new Error(`No "${exports.APP_CONFIG_FILE_NAME}" file found`);
      }
      try {
        this._config = yield fetch(`/${exports.APP_CONFIG_FILE_NAME}?v=${new Date().getTime()}`).then(response => {
          if (response.ok) {
            return response.json();
          } else {
            handleError();
          }
        });
      } catch (e) {
        handleError();
      }
    });
  }
  set config(value) {
    if (process.env.NODE_ENV !== 'test') {
      throw new Error('Manual file set has available only for a test purpose');
    }
    this._config = value;
  }
  get environment() {
    return process.env.NODE_ENV;
  }
  get backbone() {
    var _a, _b;
    return ((_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_IS_BACKBONE) === 'true' || !!((_b = window.reactAppAPI) === null || _b === void 0 ? void 0 : _b.backbone);
  }
  get version() {
    return this._config.version;
  }
  get routerBasePath() {
    return this._config.routerBasePath;
  }
  get graphqlAPI() {
    return this._config.graphqlAPI;
  }
  get restAPI() {
    if (process.env.NODE_ENV === 'test') {
      return 'http://testsqvr.ru';
    }
    return this._config.restAPI;
  }
  get socketURL() {
    return this._config.socketURL;
  }
  get restAPIOrigin() {
    return new URL(this.restAPI).origin;
  }
  get stripePublicKey() {
    return this._config.stripePublicKey;
  }
  get googleAppId() {
    return this._config.googleAppId;
  }
  get facebookAppId() {
    return this._config.facebookAppId;
  }
  get vkAppId() {
    return this._config.vkAppId;
  }
  get dadataToken() {
    return this._config.dadataToken;
  }
  get googleMapKey() {
    return this._config.googleMapKey;
  }
  get recaptchaPublicKey() {
    return this._config.recaptchaPublicKey;
  }
  get sqvrContactEmail() {
    return this._config.sqvrContactEmail;
  }
  get sentryDSN() {
    return this._config.sentryDSN || '';
  }
  get telegramLink() {
    return this._config.telegramLink;
  }
  get autotestMode() {
    return !!this._config.autotestMode;
  }
  get appHost() {
    if (this._config.appHost) {
      return this._config.appHost;
    } else if (this.computedAppHost) {
      return this.computedAppHost;
    } else {
      this.computedAppHost = this.calculateAppHostFromUrl();
      return this.computedAppHost;
    }
  }
  get hoaCode() {
    return this._config.hoaCode;
  }
  get turnOffSessionChecker() {
    return this._config.turnOffSessionChecker === true;
  }
  get turnOffRegistryUpdate() {
    return this._config.turnOffRegistryUpdate === true;
  }
  get registryTableCellUpdateDebugEnable() {
    return this._config.registryTableCellUpdateDebugEnable === true;
  }
  get enabledCerebralDebug() {
    return this._config.enabledCerebralDebug === true;
  }
  get isDemo() {
    return this._config.isDemo === true;
  }
}
exports.AppConfig = AppConfig;
const appConfig = new AppConfig();
exports.default = appConfig;