"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function downloadFileFromURL(url, filename) {
  if (typeof url !== 'string' || !url.startsWith('http', 0)) return;
  if (typeof filename !== 'string' || !filename) {
    filename = url.split('/').slice(-1)[0].split('?')[0];
  }
  const download = urlString => {
    const link = document.createElement('a');
    link.href = urlString;
    link.download = filename;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  if (typeof fetch === 'function') {
    fetch(url).then(response => response.blob()).then(blob => {
      if (blob.size === 0) {
        throw Error(`Attempting to download empty file`);
      }
      const urlString = URL.createObjectURL(blob);
      download(urlString);
    }).catch(err => {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Cannot fetch file '${url}'`, err);
      }
      download(url);
    });
  } else {
    download(url);
  }
}
exports.default = downloadFileFromURL;