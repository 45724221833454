"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function getUsernameAbbr(user) {
  const {
    avatarText
  } = user;
  if (avatarText) {
    return avatarText || '';
  } else {
    return '######';
  }
  let {
    firstName,
    lastName
  } = user;
  if (!firstName) firstName = ' ';
  if (!lastName) lastName = ' ';
  if (user.type === 'legal') return firstName[0].toUpperCase();
  return [lastName[0], firstName[0]].filter(Boolean).join('').trim().toUpperCase();
}
exports.default = getUsernameAbbr;