"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function mockPopperDocumentAPI() {
  document.createRange = () => ({
    setStart: () => {},
    setEnd: () => {},
    commonAncestorContainer: {
      nodeName: 'BODY',
      ownerDocument: document
    }
  });
}
exports.default = mockPopperDocumentAPI;