"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQueryParams = void 0;
const react_router_1 = require("react-router");
const useQueryParams = () => {
  const history = (0, react_router_1.useHistory)();
  const {
    search,
    pathname
  } = (0, react_router_1.useLocation)();
  const queryParams = new URLSearchParams(search);
  const setQueryParam = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.delete(key);
    history.push({
      pathname,
      search: queryParams.toString()
    });
  };
  const getQueryParam = key => {
    return queryParams.get(key);
  };
  const hasQueryParam = key => {
    return queryParams.has(key);
  };
  const resetQueryParams = () => {
    history.push({
      pathname,
      search: ''
    });
  };
  return {
    getQueryParam,
    setQueryParam,
    hasQueryParam,
    resetQueryParams
  };
};
exports.useQueryParams = useQueryParams;