"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const yup_1 = require("../yup");
function isEmail(value) {
  if (!value) return false;
  return yup_1.YUP_EMAIL_REGEXP.test(value.trim());
}
exports.default = isEmail;