"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZoomOut = exports.Play = exports.EraseEof = exports.ExSel = exports.CrSel = exports.Attn = exports.AltGraph = exports.Meta = exports.VolumeUp = exports.VolumeDown = exports.VolumeMute = exports.ScrollLock = exports.NumLock = exports.ContextMenu = exports.OS = exports.Delete = exports.Insert = exports.PrintScreen = exports.Execute = exports.Print = exports.Select = exports.ArrowDown = exports.ArrowRight = exports.ArrowUp = exports.ArrowLeft = exports.Home = exports.End = exports.PageDown = exports.PageUp = exports.ModeChange = exports.Accept = exports.NonConvert = exports.Convert = exports.Escape = exports.CapsLock = exports.Pause = exports.Alt = exports.Control = exports.Shift = exports.Enter = exports.Clear = exports.Tab = exports.Backspace = exports.Help = exports.Cancel = void 0;
exports.Cancel = 3;
exports.Help = 6;
exports.Backspace = 8;
exports.Tab = 9;
exports.Clear = 12;
exports.Enter = 13;
exports.Shift = 16;
exports.Control = 17;
exports.Alt = 18;
exports.Pause = 19;
exports.CapsLock = 20;
exports.Escape = 27;
exports.Convert = 28;
exports.NonConvert = 29;
exports.Accept = 30;
exports.ModeChange = 31;
exports.PageUp = 33;
exports.PageDown = 34;
exports.End = 35;
exports.Home = 36;
exports.ArrowLeft = 37;
exports.ArrowUp = 38;
exports.ArrowRight = 39;
exports.ArrowDown = 40;
exports.Select = 41;
exports.Print = 42;
exports.Execute = 43;
exports.PrintScreen = 44;
exports.Insert = 45;
exports.Delete = 46;
exports.OS = 91;
exports.ContextMenu = 93;
exports.NumLock = 144;
exports.ScrollLock = 145;
exports.VolumeMute = 181;
exports.VolumeDown = 182;
exports.VolumeUp = 183;
exports.Meta = 224;
exports.AltGraph = 225;
exports.Attn = 246;
exports.CrSel = 247;
exports.ExSel = 248;
exports.EraseEof = 249;
exports.Play = 250;
exports.ZoomOut = 251;