"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function default_1(user) {
  let withoutMiddleName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!user) return '';
  let {
    firstName,
    lastName,
    middleName,
    type,
    avatarDisplayName
  } = user;
  if (avatarDisplayName) {
    return avatarDisplayName || '';
  } else {
    return '######';
  }
  if (type === 'legal') return firstName;
  if (withoutMiddleName) {
    return [lastName || 'Неизвестный', firstName || (lastName ? '' : 'собственник')].filter(Boolean).join(' ');
  }
  return [lastName, firstName, middleName].filter(Boolean).join(' ');
}
exports.default = default_1;