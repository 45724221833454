"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
function formatNumber(amount) {
  let maximumFractionDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  let thousandsSeparator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  if ((0, lodash_1.isNil)(amount)) amount = 0;
  const text = amount.toLocaleString('en', {
    maximumFractionDigits
  }).replace(/,/g, thousandsSeparator);
  return text;
}
exports.default = formatNumber;