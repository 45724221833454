"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZoomOut = exports.Play = exports.EraseEof = exports.ExSel = exports.CrSel = exports.Attn = exports.AltGraph = exports.Meta = exports.ScrollLock = exports.NumLock = exports.ContextMenu = exports.OS = exports.Delete = exports.Insert = exports.PrintScreen = exports.Execute = exports.Print = exports.Select = exports.ArrowDown = exports.ArrowRight = exports.ArrowUp = exports.ArrowLeft = exports.Home = exports.End = exports.PageDown = exports.PageUp = exports.ModeChange = exports.Accept = exports.NonConvert = exports.Convert = exports.Escape = exports.CapsLock = exports.Pause = exports.Alt = exports.Control = exports.Shift = exports.Enter = exports.Clear = exports.Tab = exports.Backspace = exports.Help = exports.Cancel = void 0;
exports.Cancel = 'Cancel';
exports.Help = 'Help';
exports.Backspace = 'Backspace';
exports.Tab = 'Tab';
exports.Clear = 'Clear';
exports.Enter = 'Enter';
exports.Shift = 'Shift';
exports.Control = 'Control';
exports.Alt = 'Alt';
exports.Pause = 'Pause';
exports.CapsLock = 'CapsLock';
exports.Escape = 'Escape';
exports.Convert = 'Convert';
exports.NonConvert = 'NonConvert';
exports.Accept = 'Accept';
exports.ModeChange = 'ModeChange';
exports.PageUp = 'PageUp';
exports.PageDown = 'PageDown';
exports.End = 'End';
exports.Home = 'Home';
exports.ArrowLeft = 'ArrowLeft';
exports.ArrowUp = 'ArrowUp';
exports.ArrowRight = 'ArrowRight';
exports.ArrowDown = 'ArrowDown';
exports.Select = 'Select';
exports.Print = 'Print';
exports.Execute = 'Execute';
exports.PrintScreen = 'PrintScreen';
exports.Insert = 'Insert';
exports.Delete = 'Delete';
exports.OS = 'OS';
exports.ContextMenu = 'ContextMenu';
exports.NumLock = 'NumLock';
exports.ScrollLock = 'ScrollLock';
exports.Meta = 'Meta';
exports.AltGraph = 'AltGraph';
exports.Attn = 'Attn';
exports.CrSel = 'CrSel';
exports.ExSel = 'ExSel';
exports.EraseEof = 'EraseEof';
exports.Play = 'Play';
exports.ZoomOut = 'ZoomOut';