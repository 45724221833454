"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFileTypeByMimeType = void 0;
function getFileTypeByMimeType(mimetype) {
  if (!mimetype) {
    return '';
  }
  switch (mimetype) {
    case 'application/x-mswrite':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.sun.xml.writer':
    case 'application/vnd.sun.xml.writer.global':
      return 'word';
    case 'application/pdf':
      return 'pdf';
    case 'image/bmp':
    case 'image/jpeg':
    case 'image/x-citrix-jpeg':
    case 'image/pjpeg':
    case 'image/gif':
    case 'image/png':
    case 'image/x-citrix-png':
    case 'image/x-png':
    case 'image/x-icon':
    case 'image/webp':
    case 'image/heic':
    case 'image/heif':
      return 'image';
    case 'application/vnd.ms-excel':
    case 'application/xml':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.sun.xml.calc':
      return 'excel';
    case 'audio/mpeg':
    case 'audio/x-ms-wma':
    case 'audio/AMR':
    case 'audio/ogg':
    case 'audio/aac':
    case 'audio/wav':
    case 'audio/x-wav':
    case 'audio/vnd.wave':
    case 'audio/wave':
    case 'audio/x-pn-wav':
    case 'audio/flac':
      return 'audio';
    case 'video/x-msvideo':
    case 'video/mp4':
    case 'video/3gpp':
    case 'video/mpeg':
    case 'video/quicktime':
    case 'video/x-flv':
    case 'video/x-ms-wmv':
    case 'video/x-matroska':
    case 'video/mp2t':
    case 'video/x-ms-vob':
      return 'video';
    case 'application/x-zip-compressed':
    case 'application/zip-compressed':
    case 'application/zip':
    case 'application/vnd.rar':
    case 'application/x-rar-compressed':
      return 'archive';
    default:
      return 'unknown';
  }
}
exports.getFileTypeByMimeType = getFileTypeByMimeType;