"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
function omitBlankValues(source) {
  let deep = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let predicate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : x => typeof x === 'boolean' ? false : !x;
  const clonedObject = (0, lodash_1.omitBy)(source, predicate);
  if (deep) {
    Object.keys(clonedObject).forEach(key => {
      const value = clonedObject[key];
      if ((0, lodash_1.isObjectLike)(value) && Object.entries(value).length) {
        clonedObject[key] = omitBlankValues(value, true, predicate);
      }
    });
  }
  return clonedObject;
}
exports.default = omitBlankValues;