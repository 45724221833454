"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function getPhoneNumbers(value) {
  if (!value) return '';
  let result = '';
  for (let i = 0; i < value.length; i++) {
    if (value[i].match(/\d/)) result += value[i];
  }
  return result;
}
exports.default = getPhoneNumbers;